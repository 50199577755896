/*
FONTS
Headings: Cormorant Garamond
Body: Montserrat

SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px) {
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
}

LINE HEIGHT

LETTER SPACING

FONT WEIGHT

COLORS {
- Main:
- Shades:
- Tints:
- Gray: heading text color: #212529
- White: 
}

SHADOWS

BORDER RADIUS 
slight smooth: 0.9rem;
circle: 100rem;
WHITESPACE 
*/

/* ///////////////////////// */
/* GLOBAL RESET */
/* ///////////////////////// */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family: var(--font-content-sans), sans-serif;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

label {
  margin-right: auto;
}

ul,
ol {
  list-style: none;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: black;
}

/* ///////////////////////// */
/* DEFAUTLS */
/* ///////////////////////// */

.icon {
  height: 2.4rem;
  width: 2.4rem;
  color: var(--color-main);
}

.hidden {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transform: scale(0.5);
  transition: 300ms;
}

.rotate {
  transform: rotate(180deg);
}

/* REMOVE INPUT FIELD ARROWS */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* ///////////////////////// */
/* SECTION SETTINGS*/
/* ///////////////////////// */
section {
  padding: 12rem 0 0 0;
}

/* ///////////////////////// */
/* CONTAINER SETTINGS*/
/* ///////////////////////// */
.section-container {
  position: relative;
  max-width: 120rem;
  padding: 4.8rem;
  border: 2px solid black;
  border-radius: 0.9rem;
  margin: 0 auto 0 auto;
}

.section-container::after {
  content: "";
  position: absolute;
  background-image: url("/resources/img/grid-background.webp");
  background-size: contain;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.1;
}

/* ///////////////////////// */
/* FLEXBOX PRESETS */
/* ///////////////////////// */

/* ///////////////////////// */
/* GRID PRESETS */
/* ///////////////////////// */

.grid-2--cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-3--cols {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.grid-4--cols {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

/* ///////////////////////// */
/* HEADING DEFAULTS */
/* ///////////////////////// */
.section-heading {
  font-size: 4.4rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 4.8rem;
  font-family: var(--font-heading-serif), serif;
  letter-spacing: -0.05rem;
}

.subheading {
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: lowercase;
  padding: 0.8rem 1.6rem;
  letter-spacing: 0.75px;
  background-color: var(--color-main);
  border: 2px solid black;
  border-radius: 0.9rem;
  margin-bottom: 1rem;
  display: inline-block;
}

/* ///////////////////////// */
/* IMPORTED FROM BUDGETCO DEFAULTS */
/* !!! REFACTOR WHEN WORKING ON BUDGETCO CSS */
/* ///////////////////////// */

.card {
  gap: 3rem;
  padding: 3rem;
  border: 1px solid black;
  border-radius: 2rem;
  background-color: var(--color-background--purple);
  overflow: hidden;
}

/* Change to small-btn ? */
.btn {
  width: 3rem;
  height: 3rem;
}

.textarea {
  border: 0;
  padding: 0 1rem 0 1rem;
  border-radius: 1rem;
  /* resize: none;
  overflow: hidden; */
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.buttons-container {
  display: flex;
  width: 10rem;
  justify-content: space-between;
}

.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  opacity: 0;
  transition: opacity 0.5s linear;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  position: absolute;
  margin: auto auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  white-space: pre-wrap;
  border-radius: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ///////////////////////// */
/* NAVIGATION STYLES */
/* ///////////////////////// */
.nav-bar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid black;
  height: 6.4rem;
  padding: 0 3.2rem 0 3.2rem;
  top: 0;
  z-index: 1;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.689);
  backdrop-filter: blur(0.4rem);
  border-radius: 100rem;
  margin: 1.2rem 1.2rem 0 1.2rem;
}

.nav-logo {
  letter-spacing: -0.05rem;
  font-size: 3rem;
  line-height: 1.05;
}

.deesign-logo {
  font-family: var(--font-deesign--logo), serif;
}

.mimoney-logo {
  font-family: var(--font-mimoney--logo), serif;
}

.nav-btns--container {
  display: flex;
  gap: 3.2rem;
  align-items: center;
  justify-content: center;
}

.nav-btn {
  position: relative;
  height: 3.4rem;
  width: 3.4rem;
  border-radius: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms;
  border: 2px solid rgba(0, 0, 0, 0);
}

.nav-btn:hover > .nav-dot {
  background-color: white;
  border: 2px solid black;
  top: -0.8rem;
}

.icon.nav-icon {
  color: rgb(0, 0, 0);
  width: 3rem;
  --ionicon-stroke-width: 42px;
}

.nav-links {
  display: flex;
  gap: 3.2rem;
  align-items: center;
  justify-content: center;
}

.nav-links li:last-child {
  position: relative;
}
.nav-link-container a {
  width: 6.8rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link {
  display: inline-block;
  text-transform: lowercase;
  font-weight: 600;
  transform: translateY(-0.1rem);
}

.nav-link-container {
  position: relative;
}
.nav-dot {
  position: absolute;
  height: 0.6rem;
  width: 0.6rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -5rem;
  text-align: center;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgba(0, 0, 0, 0);

  transition: 300ms;
  border-radius: 300rem;
}

.nav-link:hover > .nav-dot {
  background-color: white;
  border: 2px solid black;
  top: -0.8rem;
}

.cta-link:link,
.cta-link:visited {
  color: black;

  padding: 0.6rem 2rem;
  border-radius: 0.9rem;
  border: 2px solid var(--black-main);
  background-color: var(--color-main);
  transition: 300ms;
  transform: translate(-0.4rem, -0.4rem);
}

.cta-link:hover,
.cta-link:active {
  transform: translate(0rem, 0rem);
}

.cta-link-shadow {
  position: absolute;
  background-color: black;
  height: 4rem;
  width: 100%;
  z-index: -1;
  top: 0;
  border-radius: 0.9rem;
}
