*, :before, :after {
  box-sizing: border-box;
}

* {
  font-family: var(--font-content-sans), sans-serif;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  font-size: 1.6rem;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

label {
  margin-right: auto;
}

ul, ol {
  list-style: none;
}

button {
  cursor: pointer;
  background: none;
  border: none;
}

a {
  color: #000;
  text-decoration: none;
}

.icon {
  color: var(--color-main);
  width: 2.4rem;
  height: 2.4rem;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: all .3s;
  position: absolute;
  transform: scale(.5);
}

.rotate {
  transform: rotate(180deg);
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

section {
  padding: 12rem 0 0;
}

.section-container {
  border: 2px solid #000;
  border-radius: .9rem;
  max-width: 120rem;
  margin: 0 auto;
  padding: 4.8rem;
  position: relative;
}

.section-container:after {
  content: "";
  z-index: -1;
  opacity: .1;
  background-image: url("grid-background.bb4a47b8.webp");
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.grid-2--cols {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.grid-3--cols {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

.grid-4--cols {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}

.section-heading {
  font-size: 4.4rem;
  font-weight: 500;
  line-height: 1.2;
  font-family: var(--font-heading-serif), serif;
  letter-spacing: -.05rem;
  margin-bottom: 4.8rem;
}

.subheading {
  text-transform: lowercase;
  letter-spacing: .75px;
  background-color: var(--color-main);
  border: 2px solid #000;
  border-radius: .9rem;
  margin-bottom: 1rem;
  padding: .8rem 1.6rem;
  font-size: 1.6rem;
  font-weight: 600;
  display: inline-block;
}

.card {
  background-color: var(--color-background--purple);
  border: 1px solid #000;
  border-radius: 2rem;
  gap: 3rem;
  padding: 3rem;
  overflow: hidden;
}

.btn {
  width: 3rem;
  height: 3rem;
}

.textarea {
  border: 0;
  border-radius: 1rem;
  padding: 0 1rem;
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-row {
  justify-content: start;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.flex-column {
  flex-direction: column;
  justify-content: start;
  align-items: center;
  display: flex;
}

.buttons-container {
  justify-content: space-between;
  width: 10rem;
  display: flex;
}

.modal {
  z-index: 1;
  opacity: 0;
  background-color: #0006;
  width: 100%;
  height: 100%;
  transition: opacity .5s linear;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-content {
  white-space: pre-wrap;
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 1rem;
  width: 80%;
  margin: auto;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav-bar {
  z-index: 1;
  backdrop-filter: blur(.4rem);
  background-color: #ffffffb0;
  border: 2px solid #000;
  border-radius: 100rem;
  justify-content: space-between;
  align-items: center;
  height: 6.4rem;
  margin: 1.2rem 1.2rem 0;
  padding: 0 3.2rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.nav-logo {
  letter-spacing: -.05rem;
  font-size: 3rem;
  line-height: 1.05;
}

.deesign-logo {
  font-family: var(--font-deesign--logo), serif;
}

.mimoney-logo {
  font-family: var(--font-mimoney--logo), serif;
}

.nav-btns--container {
  justify-content: center;
  align-items: center;
  gap: 3.2rem;
  display: flex;
}

.nav-btn {
  border: 2px solid #0000;
  border-radius: 30rem;
  justify-content: center;
  align-items: center;
  width: 3.4rem;
  height: 3.4rem;
  transition: all .3s;
  display: flex;
  position: relative;
}

.nav-btn:hover > .nav-dot {
  background-color: #fff;
  border: 2px solid #000;
  top: -.8rem;
}

.icon.nav-icon {
  color: #000;
  --ionicon-stroke-width: 42px;
  width: 3rem;
}

.nav-links {
  justify-content: center;
  align-items: center;
  gap: 3.2rem;
  display: flex;
}

.nav-links li:last-child {
  position: relative;
}

.nav-link-container a {
  justify-content: center;
  align-items: center;
  width: 6.8rem;
  height: 2.4rem;
  display: flex;
}

.nav-link {
  text-transform: lowercase;
  font-weight: 600;
  display: inline-block;
  transform: translateY(-.1rem);
}

.nav-link-container {
  position: relative;
}

.nav-dot {
  text-align: center;
  background-color: #fff;
  border: 2px solid #0000;
  border-radius: 300rem;
  width: .6rem;
  height: .6rem;
  margin-left: auto;
  margin-right: auto;
  transition: all .3s;
  position: absolute;
  top: -5rem;
  left: 0;
  right: 0;
}

.nav-link:hover > .nav-dot {
  background-color: #fff;
  border: 2px solid #000;
  top: -.8rem;
}

.cta-link:link, .cta-link:visited {
  color: #000;
  border: 2px solid var(--black-main);
  background-color: var(--color-main);
  border-radius: .9rem;
  padding: .6rem 2rem;
  transition: all .3s;
  transform: translate(-.4rem, -.4rem);
}

.cta-link:hover, .cta-link:active {
  transform: translate(0);
}

.cta-link-shadow {
  z-index: -1;
  background-color: #000;
  border-radius: .9rem;
  width: 100%;
  height: 4rem;
  position: absolute;
  top: 0;
}
/*# sourceMappingURL=index.d0bd0239.css.map */
